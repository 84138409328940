import React, { useState } from "react";
import "./Feedback.scss";
import { MdOutlineThumbUp } from "react-icons/md";
import { MdOutlineThumbDown } from "react-icons/md";
import axios from "axios";
import { SERVER_URL } from "../../config";

const FeedbackModal = ({ isOpen, onClose, onSubmit, value, onChange }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h3>추가 의견</h3>
        <textarea
          value={value}
          onChange={onChange}
          placeholder="추가 의견을 입력해주세요 (선택사항)"
        />
        <div className="modal-actions">
          <button onClick={onClose}>취소</button>
          <button onClick={onSubmit}>제출</button>
        </div>
      </div>
    </div>
  );
};

const Feedback = ({ messageId, userInfo }) => {
  const [feedbackScore, setFeedbackScore] = useState(null);
  const [additionalFeedback, setAdditionalFeedback] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleFeedback = async (value) => {
    if (submitted) return; // 이미 제출된 경우 더 이상의 상호작용 방지
    setFeedbackScore(value);
    // "user_id": 0,
    // "message_id": "string",
    // "like": true,
    // "content": "string"
    const feedback = {
      user_id: userInfo.id,
      message_id: messageId,
      like: value,
      content: "",
    };

    try {
      const response = await axios.post(
        `${SERVER_URL}/feedback/user_feedback`,
        feedback
      );
      setSubmitted(true);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
    setIsModalOpen(true);
  };

  const handleAdditionalFeedback = async () => {
    if (feedbackScore !== null) {
      const feedback = {
        user_id: userInfo.id,
        message_id: messageId,
        like: feedbackScore,
        content: additionalFeedback,
      };

      try {
        const response = await axios.post(
          `${SERVER_URL}/feedback/user_feedback`,
          feedback
        );
        setIsModalOpen(false);
      } catch (error) {
        console.error("Error sending feedback:", error);
      }
    }
  };

  const handleInputChange = (e) => {
    setAdditionalFeedback(e.target.value);
  };

  return (
    <div className="feedback-container">
      <div className="feedback-buttons">
        <button
          className={`feedback-button ${feedbackScore === 1 ? "active" : ""} ${
            submitted && feedbackScore === 1 ? "submitted" : ""
          }`}
          onClick={() => handleFeedback(1)}
          disabled={submitted}
        >
          <MdOutlineThumbUp />
        </button>
        <button
          className={`feedback-button ${feedbackScore === 0 ? "active" : ""} ${
            submitted && feedbackScore === 0 ? "submitted" : ""
          }`}
          onClick={() => handleFeedback(0)}
          disabled={submitted}
        >
          <MdOutlineThumbDown />
        </button>
      </div>
      <FeedbackModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleAdditionalFeedback}
        value={additionalFeedback}
        onChange={handleInputChange}
      />
    </div>
  );
};
export default Feedback;
