import React from "react";
import { CgCloseR } from "react-icons/cg";
import "./Reference.scss";

const Reference = ({ isOpen, onClose, references }) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target.className === "references-popup-overlay") {
      onClose();
    }
  };

  return (
    <div className="references-popup-overlay" onClick={handleOverlayClick}>
      <div className="references-popup-content">
        <div className="header">
          <h3>참고한 사규 내용 : </h3>
          <button className="close-button" onClick={onClose}>
            <CgCloseR />
          </button>
        </div>
        <div className="references-container">
          <ul className="references-list">
            {references.map((ref, index) => (
              <li key={index}>
                <div className="title">
                  <strong>
                    {ref.code && ref.code !== "NONE"
                      ? `📑 ${ref.code} | ${ref.source}`
                      : `📑 ${ref.source}`}
                  </strong>
                  &nbsp;<i>(number: {ref.page})</i>
                </div>
                <div className="content">{ref.page_content}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Reference;
